import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { PlainBtn } from '@/buttons';
import { ReactComponent as CloseIcon } from '@a/icons/close.svg';
import { ReactComponent as ArrowIcon } from '@a/icons/right-arrow.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import useRouteChange from '@s/hooks/useRouteChange';
import { useEffect, useState } from 'react';
import { navBtnStyles, subNavLinkStyles } from './NavLinkStyles';
import { NavLinksTypes } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexStart, px } from './styles/classes';

const overlayStyles = css`
    display: none;
`;

const contentStyles = css`
    ${px};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 102;
    padding-top: 16px;
    padding-bottom: 16px;
    max-width: 976px;

    > button:first-of-type {
        margin-bottom: 32px;

        > svg rect {
            transition: fill 0.3s ease-in-out;
        }

        :hover {
            > svg rect {
                fill: ${theme.colors.blue};
            }
        }

        :focus-visible {
            > svg rect {
                fill: ${theme.colors.blue};
            }
        }
    }

    > nav {
        ${flexStart};
        flex-direction: column;
        align-items: center;
        height: calc(100% - (48px + 32px + (1.875rem * 1.35) + 32px + 32px));
        overflow-y: auto;

        > a:last-child {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    @media (min-width: 1280px) {
        > nav {
            height: calc(100% - (48px + 32px + (2.25rem * 1.2) + 32px + 48px));
        }
    }
`;

const Heading = styled.h3`
    margin: 0;
    ${navBtnStyles};
    margin-left: auto;
    margin-right: auto;
    max-width: max-content;
    display: flex;
    pointer-events: none;

    ::after {
        opacity: 1;
        height: 4px;
    }

    @media (min-width: 1280px) {
        margin-bottom: 40px;
    }
`;

type SubNavProps = {
    text: string;
    links: NavLinksTypes;
    setSubDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SubNav = ({ text, links, setSubDialogOpen }: SubNavProps) => {
    const [open, setOpen] = useState(false);

    useRouteChange(setOpen);

    const { category } = useLocationData();

    useEffect(() => {
        setSubDialogOpen(open);
    }, [open, setSubDialogOpen]);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogOpen>
                <PlainBtn
                    aria-label={`open ${text} menu`}
                    css={navBtnStyles}
                    className={category === text ? 'current-nav-category' : ''}
                >
                    {text} <ArrowIcon />
                </PlainBtn>
            </DialogOpen>

            <DialogContent
                contentStyles={contentStyles}
                overlayStyles={overlayStyles}
                title={`${text} navigation menu`}
            >
                <DialogClose>
                    <PlainBtn aria-label="close the nav menu">
                        <CloseIcon />
                    </PlainBtn>
                </DialogClose>
                <Heading>{text}</Heading>
                <nav>
                    {links.map((data, i) => (
                        <CLink to={data.link} css={subNavLinkStyles} key={i}>
                            {data.text}
                        </CLink>
                    ))}
                </nav>
            </DialogContent>
        </Dialog>
    );
};
