import { flexSpace, mw, px } from '@/layout/styles/classes';
import lines from '@a/images/homepage/oscar/lines.svg';
import shape from '@a/images/homepage/oscar/shape.svg';
import styled from '@emotion/styled';

export const BgLineSection = styled.section`
    ${px};
    padding-top: 32px;
    background-color: ${({ theme }) => theme.colors.extraDark};
    position: relative;
    overflow: hidden;
    isolation: isolate;

    > div {
        ${mw};
        margin: 0 auto;

        ::before {
            content: url(${lines});
            position: absolute;
            right: -100px;
            top: -200px;
            bottom: 0;
            opacity: 0.4;
            z-index: -1;
        }
    }

    ::before,
    ::after {
        content: url(${shape});
        position: absolute;
        z-index: -1;
        max-height: 1266px;
    }

    ::before {
        top: 0;
        left: 0;
        transform: scale(-1);
    }

    ::after {
        right: 0;
        bottom: 0;
    }

    @media (min-width: 1024px) {
        > div {
            ${flexSpace};
            align-items: flex-end;
        }
    }

    @media (min-width: 1280px) {
        padding-top: 64px;
    }
`;
