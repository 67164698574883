import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { PlainBtn } from '@/buttons';
import { ReactComponent as CloseIcon } from '@a/icons/close.svg';
import { ReactComponent as MenuIcon } from '@a/icons/menu.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useRouteChange from '@s/hooks/useRouteChange';
import { useState } from 'react';
import { scrollToSection } from './Nav';
import { navLinkStyles } from './NavLinkStyles';
import { navLinks } from './NavLinks';
import { SubNav } from './SubNav';
import { theme } from './styles/GlobalStyles';
import { flexStart, px } from './styles/classes';

const MenuBtn = styled(PlainBtn)`
    transition: opacity 0.3s ease-in-out;

    :hover {
        opacity: 0.6;
    }

    :focus-visible {
        opacity: 0.6;
    }
`;

const overlayStyles = css`
    background-color: rgb(8, 28, 68, 0.8);
`;

const contentStyles = css`
    ${px};
    background-color: rgba(0, 0, 0, 0.34);

    padding-top: 16px;
    padding-bottom: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 128px 128px 0;
    box-shadow:
        128px 0px 250px -64px rgba(0, 0, 0, 0.5),
        -64px 64px 192px -32px rgba(255, 255, 255, 0.1) inset;
    backdrop-filter: blur(16px);
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-left-width: 0;
    max-width: 976px;

    > button:first-of-type {
        margin-bottom: 32px;

        > svg rect {
            transition: fill 0.3s ease-in-out;
        }

        :hover {
            > svg rect {
                fill: ${theme.colors.blue};
            }
        }

        :focus-visible {
            > svg rect {
                fill: ${theme.colors.blue};
            }
        }
    }

    @media (min-width: 1024px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
`;

const NavMenu = styled.nav<{ subDialogOpen: boolean }>`
    ${flexStart};
    flex-direction: column;
    align-items: center;
    transition:
        opacity 0.3s ease-in-out,
        visibility 0.3s ease-in-out;
    max-height: calc(100% - 96px);
    overflow-y: auto;

    > a:last-child {
        margin-bottom: 0;
    }

    ${({ subDialogOpen }) =>
        subDialogOpen &&
        css`
            visibility: ${subDialogOpen ? 'hidden' : 'visible'};
            pointer-events: ${subDialogOpen ? 'none' : 'auto'};
            opacity: ${subDialogOpen ? 0 : 1};
        `};
`;

export const NavDialog = () => {
    const [open, setOpen] = useState(false);
    const [subDialogOpen, setSubDialogOpen] = useState(false);

    useRouteChange(setOpen);

    const sectionScroll = (link: string) => {
        setOpen(false);
        setTimeout(() => scrollToSection(link), 100);
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogOpen>
                <MenuBtn aria-label="open navigation menu">
                    <MenuIcon />
                </MenuBtn>
            </DialogOpen>

            <DialogContent
                contentStyles={contentStyles}
                overlayStyles={overlayStyles}
                title="website main navigation menu"
            >
                <DialogClose>
                    <PlainBtn aria-label="close the nav menu">
                        <CloseIcon />
                    </PlainBtn>
                </DialogClose>
                <NavMenu subDialogOpen={subDialogOpen}>
                    {navLinks.map((data, i) =>
                        data.links ? (
                            <SubNav
                                key={i}
                                text={data.text}
                                links={data.links}
                                setSubDialogOpen={setSubDialogOpen}
                            />
                        ) : data.link.startsWith('#') ? (
                            <PlainBtn
                                onClick={() => sectionScroll(data.link)}
                                css={navLinkStyles}
                                key={i}
                            >
                                {data.text}
                            </PlainBtn>
                        ) : (
                            <CLink to={data.link} css={navLinkStyles} key={i}>
                                {data.text}
                            </CLink>
                        )
                    )}
                </NavMenu>
            </DialogContent>
        </Dialog>
    );
};
