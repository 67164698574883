import { plainBtnStyles } from '@/buttons/Plain';
import { css } from '@emotion/react';
import { theme } from './styles/GlobalStyles';
import { oswald } from './styles/Typography';

const dynamicStyles = css`
    position: relative;

    ::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background-color: ${theme.colors.blue};
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
    &[aria-current='page'] {
        color: ${theme.colors.blue};
    }

    &.current-nav-category {
        color: ${theme.colors.blue};

        > svg path {
            fill: ${theme.colors.blue};
        }
    }

    @media (pointer: fine) {
        :hover {
            ::after {
                opacity: 1;
            }
        }

        :focus-visible {
            ::after {
                opacity: 1;
            }
        }
    }

    @media (min-width: 1280px) {
        ::after {
            height: 4px;
        }
    }
`;

export const navLinkStyles = css`
    ${dynamicStyles};
    ${oswald};
    padding: 16px;
    text-transform: capitalize;
    color: ${theme.colors.white};
    transition: color 0.3s ease-in-out;
    margin-bottom: 32px;
    font-size: 1.875rem;
    text-transform: uppercase;

    @media (min-width: 1280px) {
        font-size: 2.25rem;
    }
`;

export const subNavLinkStyles = css`
    ${navLinkStyles};
    font-size: 1.5rem;

    @media (min-width: 1280px) {
        font-size: 2rem;
    }
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};

    > svg {
        margin-left: 16px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${theme.colors.white};
        }
    }
`;
