import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { h2, h4 } from './styles/Typography';
import { mw, npx, px, py, underlineLeft } from './styles/classes';

const Section = styled.section`
    ${px};
    ${py};
    z-index: 2;
    position: relative;

    > h2 {
        ${h4};
        ${mw};
        color: ${({ theme }) => theme.colors.blue};
        margin: 0 auto;
        letter-spacing: 6px;
    }

    > h3 {
        ${h2};
        ${mw}
        ${underlineLeft}
        margin: 8px auto 48px;
    }
`;

const Cards = styled.div`
    ${npx};
    display: flex;
    overflow-x: auto;
    gap: 16px;

    @media (min-width: 768px) {
        gap: 32px;
    }

    @media (min-width: 1024px) {
        overflow-x: unset;
        margin: 0 !important;
    }

    @media (min-width: 1280px) {
        gap: 64px;
        justify-content: center;
    }
`;

const Card = styled.div`
    border-radius: 32px;
    overflow: hidden;
    opacity: 0.9999; //safari overflow bug fix
    display: grid;
    max-width: max-content;
    flex-shrink: 0;
    max-width: 313px;

    > .gatsby-image-wrapper {
        min-height: 360px;
    }

    :first-of-type {
        margin-left: 16px;
    }

    :last-of-type {
        margin-right: 16px;
    }

    > * {
        grid-area: 1/1;
    }

    > a {
        ${h4};
        display: flex;
        align-items: center;
        border-radius: 16px;
        box-shadow: 8px -8px 0px 0px #fff;
        background-color: ${({ theme }) => theme.colors.dark};
        padding: 16px;
        align-self: end;
        z-index: 1;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.white};
        justify-self: center;
        transition: background-color 0.3s ease-in-out;
        box-shadow: 8px -8px 0px 0px #fff;

        > svg {
            margin-left: 16px;
        }

        :hover,
        :focus {
            background-color: ${({ theme }) => theme.colors.blue};
        }
    }

    @media (min-width: 768px) {
        :first-of-type {
            margin-left: 32px;
        }

        :last-of-type {
            margin-right: 32px;
        }
    }

    @media (min-width: 1024px) {
        flex: 1;
        max-width: 513px;

        :first-of-type {
            margin-left: 0;
        }

        :last-of-type {
            margin-right: 0;
        }
    }

    @media (min-width: 1280px) {
        max-width: 486px;
    }

    @media (min-width: 1440px) {
        > .gatsby-image-wrapper {
            height: 443px;
        }
    }
`;

export const Activities = () => {
    return (
        <Section>
            <h2>DISCOUNTED ACTIVITIES AROUND YOU</h2>
            <h3>ACTIVITIES AROUND YOU</h3>
            <Cards>
                <Card>
                    <StaticImage
                        src="../../../assets/images/layout/activities/diving.jpg"
                        alt="two scuba divers surrounded by fish"
                        width={486}
                        height={443}
                    />
                    {/* <CLink to="">
                        SCUBA DIVING <ArrowIcon />
                    </CLink> */}
                </Card>{' '}
                <Card>
                    <StaticImage
                        src="../../../assets/images/layout/activities/explore.jpg"
                        alt="beautiful waterfall"
                        width={486}
                        height={443}
                    />
                    {/* <CLink to="">
                        explore <ArrowIcon />
                    </CLink> */}
                </Card>{' '}
                <Card>
                    <StaticImage
                        src="../../../assets/images/layout/activities/hiking.jpg"
                        alt="woman with backpack crossing a bridge during hiking "
                        width={486}
                        height={443}
                    />
                    {/* <CLink to="">
                        hiking <ArrowIcon />
                    </CLink> */}
                </Card>
            </Cards>
        </Section>
    );
};
