import { CheckBoxes, Error, Form, Input, Label, Thanks } from '@/FormComponents';
import { PrimaryBtn } from '@/buttons';
import email from '@a/icons/email.svg';
import person from '@a/icons/person.svg';
import phone from '@a/icons/phone.svg';
import { css } from '@emotion/react';
import { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { navLinks } from './NavLinks';

export const ContactForm = () => {
    const [submit, setSubmit] = useState<'init' | 'submitting' | 'submitted' | 'error'>('init');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmit('submitting');

        const formData = new FormData(e.currentTarget);
        const data = Object.fromEntries(formData);
        data['interests'] = formData.getAll('interests').join(', ');

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(() => {
                setSubmit('submitted');
                e.target.reset();
            })
            .catch(() => {
                setSubmit('error');
            });
    };

    return (
        <Form
            onSubmit={handleSubmit}
            submit={submit === 'submitted' || submit === 'error'}
            id="contact-us"
        >
            <Label
                htmlFor="name"
                icon
                css={css`
                    ::before {
                        background-image: url(${person});
                    }
                `}
            >
                Name *
            </Label>
            <Input type="text" id="name" name="name" placeholder="Full Name " required icon />

            <Label
                htmlFor="phone"
                icon
                css={css`
                    ::before {
                        background-image: url(${phone});
                    }
                `}
            >
                Phone *
            </Label>

            <PatternFormat
                type="tel"
                format="+1 (###) ###-####"
                customInput={Input}
                placeholder="123 456-7890"
                id="phone"
                name="phone"
                required
                icon
            />

            <Label
                htmlFor="email"
                icon
                css={css`
                    ::before {
                        background-image: url(${email});
                    }
                `}
            >
                Email
            </Label>
            <Input type="email" id="email" name="email" placeholder="example@gmail.com" icon />

            <CheckBoxes>
                <Label as="p">Select All that you are interested in:</Label>
                {navLinks[1].links?.map((link, i) => (
                    <div key={i}>
                        <input
                            type="checkbox"
                            id={`interests${i + 1}`}
                            name="interests"
                            value={link.text}
                        />
                        <label htmlFor={`interests${i + 1}`}>{link.text}</label>
                    </div>
                ))}
                <div>
                    <input type="checkbox" id={`interests20`} name="interests" value="other" />
                    <label htmlFor={`interests20`}>other</label>
                </div>
            </CheckBoxes>

            <Label htmlFor="message">Message</Label>

            <Input
                as="textarea"
                id="message"
                name="message"
                rows={7}
                placeholder="Your Message for Us"
            />
            <PrimaryBtn
                disabled={submit === 'submitting'}
                type="submit"
                style={{ display: 'flex', maxWidth: 'max-content', margin: '0 auto' }}
            >
                {submit === 'submitting' ? 'Requesting...' : 'Request A CALL BACK'}
            </PrimaryBtn>
            <Thanks submit={submit === 'submitted'} setSubmit={setSubmit} />
            <Error error={submit === 'error'} />
        </Form>
    );
};
