import { css } from '@emotion/react';

export const px = css`
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 1024px) {
        padding-left: 48px;
        padding-right: 48px;
    }

    @media (min-width: 1280px) {
        padding-left: 64px;
        padding-right: 64px;
    }
`;

export const npx = css`
    margin-left: -16px;
    margin-right: -16px;

    @media (min-width: 768px) {
        margin-left: -32px;
        margin-right: -32px;
    }

    @media (min-width: 1024px) {
        margin-left: -48px;
        margin-right: -48px;
    }

    @media (min-width: 1280px) {
        margin-left: -64px;
        margin-right: -64px;
    }
`;

export const py = css`
    padding-top: 32px;
    padding-bottom: 32px;

    @media (min-width: 1024px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    @media (min-width: 1280px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
`;

export const pyb = css`
    padding-top: 32px;
    padding-bottom: 32px;

    @media (min-width: 1024px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    @media (min-width: 1280px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
`;

export const ml = css`
    margin-left: 16px;

    @media (min-width: 768px) {
        margin-left: 32px;
    }

    @media (min-width: 1024px) {
        margin-left: 48px;
    }

    @media (min-width: 1280px) {
        margin-left: 64px;
    }
`;

export const mr = css`
    margin-right: 16px;

    @media (min-width: 768px) {
        margin-right: 32px;
    }

    @media (min-width: 1024px) {
        margin-right: 48px;
    }

    @media (min-width: 1280px) {
        margin-right: 64px;
    }
`;

export const flexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const flexSpace = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const flexStart = css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const mw = css`
    max-width: max(1312px, min(1596px, 91.11vw));
`;

export const maxWidth = css`
    max-width: max(1184px, min(1440px, 82.22vw));
`;

export const underlineCenter = css`
    position: relative;
    padding-bottom: 8px;

    ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        width: 180px;
        max-width: 50vw;
        background-color: #3361ed;
        box-shadow: 0px 8px 16px -2px #3361ed;
    }

    @media (min-width: 1280px) {
        padding-bottom: 16px;

        ::after {
            height: 8px;
        }
    }
`;

export const underlineLeft = css`
    position: relative;
    padding-bottom: 8px;

    ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 180px;
        max-width: 50vw;
        background-color: #3361ed;
        box-shadow: 0px 8px 16px -2px #3361ed;
    }

    @media (min-width: 1280px) {
        padding-bottom: 16px;

        ::after {
            height: 8px;
        }
    }
`;
