import { lgTxt, smTxt } from '@/layout/styles/Typography';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Dispatch, SetStateAction } from 'react';
import { SecondaryBtn } from './buttons';

export const Form = styled.form<{ submit: boolean }>`
    position: relative;
    max-width: 520px;
    padding: 32px 16px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(16px);
    border-radius: 32px;
    scroll-margin-top: 100px;

    > button:last-of-type {
        margin: 24px auto 0;
        display: flex;
        max-width: max-content;
    }

    ${({ submit }) =>
        submit &&
        css`
            * {
                pointer-events: none;
                visibility: hidden;
            }
        `};

    @media (min-width: 1024px) {
        width: 400px;
        padding: 40px;
        flex-shrink: 0;
    }

    @media (min-width: 1280px) {
        padding: 48px 64px;
        width: 520px;
    }
`;

export const Input = styled.input<{ rows?: number; icon?: boolean }>`
    ${smTxt};
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid transparent;
    width: 100%;
    padding: ${({ icon }) => (icon ? '8px 16px 8px 40px' : '8px 16px')};
    display: block;
    outline: none;
    transition: border-color 0.3s ease-in;
    margin-bottom: 24px;
    resize: none;
    background-color: rgba(41, 45, 50, 0.5);

    :hover {
        border-color: ${({ theme }) => theme.colors.blue};
    }

    :focus-visible {
        border-color: ${({ theme }) => theme.colors.blue};
    }

    ::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    option {
        text-transform: capitalize;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.white};
        -webkit-box-shadow: 0 0 0px 1000px rgba(41, 45, 50, 0.5) inset;
        transition:
            background-color 50000s ease-in-out 0s,
            border-color 0.3s ease-in;
    }

    :focus:invalid {
        border-color: #d50c39;
    }

    @media (min-width: 1024px) {
        margin-bottom: 32px;
    }
`;

export const Label = styled.label<{ icon?: boolean }>`
    ${smTxt};
    margin-bottom: 8px;
    display: inline-block;
    text-transform: capitalize;
    transition: color 0.3s ease-in-out;
    color: ${({ theme }) => theme.colors.white};

    ${({ icon }) =>
        icon &&
        css`
            position: relative;

            ::before {
                content: '';
                width: 16px;
                height: 16px;
                position: absolute;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                top: 48px;
                left: 15px;
            }
        `};

    :hover {
        color: ${({ theme }) => theme.colors.blue};
    }

    @media (min-width: 1280px) {
        ::before {
            top: 52px;
        }
    }
`;

export const CheckBoxes = styled.div`
    margin-bottom: 24px;

    > p {
        margin: 0 0 8px;

        :hover {
            color: ${({ theme }) => theme.colors.white};
        }
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 16px;
        max-width: max-content;

        > label {
            ${smTxt};
            text-transform: capitalize;
            margin-left: 10px;
            transition: color 0.3s ease-in-out;
            color: ${({ theme }) => theme.colors.white};

            :hover {
                color: ${({ theme }) => theme.colors.blue};
            }
        }

        > input {
            width: 24px;
            height: 24px;
            accent-color: ${({ theme }) => theme.colors.blue};
            transition:
                accent-color 0.3s ease-in-out,
                box-shadow 0.3s ease-in-out;

            :focus-visible {
                box-shadow: inset 0 0 0 50px ${({ theme }) => theme.colors.blue};
            }

            :checked {
                box-shadow: none;
            }
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 36px;
    }
`;

const StyledMsg = styled.div<{ submit: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    color: ${({ theme }) => theme.colors.white};

    &,
    > h2,
    > p,
    > button {
        visibility: ${({ submit }) => (submit ? 'visible' : 'hidden')} !important;
        pointer-events: ${({ submit }) => (submit ? 'all' : 'none')} !important;
    }

    > h2 {
        margin: 0;
    }

    > p {
        ${lgTxt};
        margin: 2rem 0;
    }

    @media (min-width: 768px) {
        padding: 64px;
    }
`;

export const Thanks = ({
    submit,
    setSubmit,
}: {
    submit: boolean;
    setSubmit: Dispatch<SetStateAction<'init' | 'submitting' | 'submitted' | 'error'>>;
}) => (
    <StyledMsg submit={submit}>
        <h2>Thank you</h2>
        <p>Someone will be right with you shortly!</p>

        <SecondaryBtn onClick={() => setSubmit('init')} aria-hidden={!submit}>
            Resubmit Form
        </SecondaryBtn>
    </StyledMsg>
);

export const Error = ({ error }: { error: boolean }) => (
    <StyledMsg submit={error}>
        <h2>Something went wrong</h2>
        <p>Please give us a call instead.</p>
    </StyledMsg>
);
