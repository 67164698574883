import { CLink } from '@/CLink';
import styled from '@emotion/styled';
import { HomeLogo } from './Nav';
import { footerNavLinks } from './NavLinks';
import { flexSpace, flexStart, mw, px, py } from './styles/classes';

const Section = styled.footer`
    ${px};
    ${py};
    background-image: ${({ theme }) => theme.colors.gradient};
    color: ${({ theme }) => theme.colors.white};

    > a:first-of-type {
        display: block;
        max-width: max-content;
        margin: 0 auto;
    }
`;

const Flex = styled.div`
    ${mw};
    margin: 32px auto;
    padding-top: 32px;
    border-top: 1px solid ${({ theme }) => theme.colors.white};

    @media (min-width: 1024px) {
        ${flexSpace};
        align-items: flex-start;
    }

    @media (min-width: 1280px) {
        padding-top: 64px;
        margin: 64px auto;
    }
`;

const Links = styled.div`
    :not(:last-of-type) {
        margin-bottom: 48px;
    }

    > h4 {
        margin: 0 0 32px;
    }

    > a {
        ${flexStart};
        align-items: center;
        transition: color 0.3s ease-in-out;
        margin-bottom: 20px;
        word-break: break-word;
        max-width: max-content;
        color: ${({ theme }) => theme.colors.gray};
        text-transform: capitalize;

        :last-of-type {
            margin-bottom: 0;
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.blue};
        }
    }

    @media (min-width: 1024px) {
        :not(:last-of-type) {
            margin-bottom: 0;
        }
    }
`;

const Copyright = styled.p`
    font-size: 0.875rem;
    margin: 0;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray};
`;

export const Footer = () => {
    return (
        <Section>
            <HomeLogo />
            <Flex>
                <Links>
                    <h4>Discover</h4>
                    {footerNavLinks.slice(0, 4).map((data, i) => (
                        <CLink key={i} to={data.link}>
                            {data.text}
                        </CLink>
                    ))}
                </Links>
                <Links>
                    <h4>Company</h4>
                    {footerNavLinks.slice(4, 8).map((data, i) => (
                        <CLink key={i} to={data.link}>
                            {data.text}
                        </CLink>
                    ))}
                </Links>
                <Links>
                    <h4>Popular Services</h4>
                    {footerNavLinks.slice(8).map((data, i) => (
                        <CLink key={i} to={data.link}>
                            {data.text}
                        </CLink>
                    ))}
                </Links>
            </Flex>
            <Copyright>
                Oscar Vargas Cento Dental © {new Date().getFullYear()}. All rights reserved.
            </Copyright>
        </Section>
    );
};
