import { BgLineSection } from '@/BgLineSection';
import { CLink } from '@/CLink';
import { ReactComponent as EmailIcon } from '@a/icons/email.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as PinIcon } from '@a/icons/pin.svg';
import { ReactComponent as WhatsappIcon } from '@a/icons/whatsapp.svg';
import styled from '@emotion/styled';
import { ContactForm } from './ContactForm';
import { siteInfo } from './NavLinks';
import { h2, h4 } from './styles/Typography';
import { flexCenter, py, underlineLeft } from './styles/classes';

const Section = styled(BgLineSection)`
    ${py};

    > div {
        ::before {
            right: 120px;
            top: -100px;
        }
    }

    @media (min-width: 1024px) {
        padding-top: 228px;
        margin-top: -228px;

        > div {
            align-items: center;
        }
    }

    @media (min-width: 1280px) {
        padding-top: 244px;
        margin-top: -244px;
    }

    @media (min-width: 1440px) {
        padding-top: 285px;
        margin-top: -285px;
    }
`;

const Box = styled.div`
    max-width: 700px;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 32px;
    scroll-margin-top: 110px;

    > h2 {
        ${h4};
        margin: 0;
        letter-spacing: 6px;
        color: ${({ theme }) => theme.colors.blue};
        letter-spacing: 6px;
    }

    > h3 {
        ${h2};
        ${underlineLeft};
        margin: 8px 0 48px;
    }

    @media (min-width: 1024px) {
        margin-bottom: 0;
        margin-right: 32px;
    }
`;

const Address = styled.div`
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    max-width: 500px;

    > p {
        ${h4};
        margin: 0;
    }

    > a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.white};
        transition: color 0.3s ease-in-out;

        ${flexCenter};
        justify-content: flex-start;
        margin: 8px 0 0;

        > svg {
            margin-right: 16px;
            flex-shrink: 0;

            path {
                transition: fill 0.3s ease-in-out;
            }
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.blue};

            > svg path {
                fill: ${({ theme }) => theme.colors.blue};
            }
        }
    }
`;

const Link = styled(CLink)`
    ${flexCenter};
    justify-content: flex-start;
    margin: 0 0 16px;

    :last-of-type {
        margin-bottom: 32px;
    }

    > span {
        text-decoration: underline;
        transition: color 0.3s ease-in-out;
        color: ${({ theme }) => theme.colors.white};
    }
    > svg {
        margin-right: 8px;
    }

    :hover,
    :focus {
        > span {
            color: ${({ theme }) => theme.colors.blue};
        }
    }
`;

const Iframe = styled.iframe`
    width: 700px;
    max-width: 100%;
    height: 91.46vw;
    max-height: 520px;
    border: none;
    margin-top: 32px;
    display: block;

    @media (min-width: 1280px) {
        margin-top: 64px;
    }
`;

export const ContactUs = () => {
    return (
        <Section>
            <div>
                <Box id="directions">
                    <h2>let’s get in touch</h2>
                    <h3>CONTACT US</h3>
                    <Address>
                        <p>Downtown</p>
                        <CLink to={siteInfo.address.link}>
                            <PinIcon /> {siteInfo.address.text}
                        </CLink>
                    </Address>
                    <Address>
                        <p>Midtown</p>
                        <CLink to={siteInfo.address2.link}>
                            <PinIcon /> {siteInfo.address2.text}
                        </CLink>
                    </Address>
                    <Link to={siteInfo.whatsapp.link}>
                        <WhatsappIcon /> <span>{siteInfo.whatsapp.text}</span>
                    </Link>
                    <Link to={siteInfo.phone.link}>
                        <PhoneIcon /> <span>{siteInfo.phone.text}</span>
                    </Link>
                    <Link to={siteInfo.email.link}>
                        <EmailIcon /> <span>{siteInfo.email.text}</span>
                    </Link>

                    <Iframe
                        src="https://www.google.com/maps/d/embed?mid=167dzFxlLjcKsAqIm7zCyb8PnzkIvm18&ehbc=2E312F"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></Iframe>
                </Box>
                <ContactForm />
            </div>
        </Section>
    );
};
