export const siteInfo = {
    phone: {
        text: '+506 2259-1530',
        link: 'tel:+506 2259-1530',
    },
    whatsapp: {
        text: '+50687667472',
        link: 'https://wa.me/50687667472/?text=Hola,%20deseo%20m%C3%A1s%20informaci%C3%B3n,%20solicitud%20desde%20p%C3%A1gina%20web:',
    },
    email: {
        text: 'info@oscarvargascentrodental.com',
        link: 'mailto:info@oscarvargascentrodental.com',
    },
    address: {
        text: 'Torre Médica, Mometum Escazú, San José Province, Escazu, 10201, Costa Rica',
        link: 'https://maps.app.goo.gl/eQ17mY7wUfFyS7567',
    },
    address2: {
        text: 'Desamparados, San José, 10301, Costa Rica',
        link: 'https://maps.app.goo.gl/xSD5P3qJqBLpfZzc6',
    },
    social: {
        linkedin: 'https://cr.linkedin.com/pub/oscar-vargas-fdz/86/108/b30',
        facebook: 'https://www.facebook.com/oscarvargascentrodental',
        instagram: 'https://www.instagram.com/oscarvargascentrodental/',
        youtube: 'https://www.youtube.com/channel/UCcmLqc6aNi33BpMXjDUddeQ',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
        links: [
            {
                text: 'about us',
                link: '/about-us/',
            },
            {
                text: 'Dr. Oscar Vargas Fernández',
                link: '/about-us/dr-oscar-vargas/',
            },
            {
                text: 'Dr. Diego Ramirez',
                link: '/about-us/dr-diego-ramirez/',
            },
        ],
    },
    {
        text: 'services',
        link: '/services/',
        links: [
            {
                text: 'dental implants',
                link: '/services/dental-implants/',
            },
            {
                text: 'porcelain veneers',
                link: '/services/porcelain-veneers/',
            },
            {
                text: 'dental prevention',
                link: '/services/dental-prevention/',
            },
            {
                text: 'smile design',
                link: '/services/smile-design/',
            },
            {
                text: 'zirconia crowns',
                link: '/services/zirconia-crowns/',
            },
            {
                text: 'teeth whitening',
                link: '/services/teeth-whitening/',
            },
            {
                text: 'direct and indirect resins',
                link: '/services/direct-and-indirect-resins/',
            },
        ],
    },
    {
        text: 'before and after',
        link: '/before-and-after/',
    },
    {
        text: 'contact us',
        link: '#contact-us',
    },
];

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'home',
        link: '/',
    },
    {
        text: 'case studies',
        link: getNavLink('before and after'),
    },
    {
        text: 'contact us',
        link: '#contact-us',
    },
    {
        text: 'costa rica',
        link: '',
    },
    {
        text: 'about us',
        link: getNavLink('about us'),
    },
    {
        text: 'Dr. Oscar Vargas Fernández',
        link: getNavLink('Dr. Oscar Vargas Fernández'),
    },
    {
        text: 'Dr. Diego Ramirez',
        link: getNavLink('Dr. Diego Ramirez'),
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
    {
        text: 'dental implants',
        link: getNavLink('dental implants'),
    },
    {
        text: 'porcelain veneers',
        link: getNavLink('porcelain veneers'),
    },
    {
        text: 'zirconia crowns',
        link: getNavLink('zirconia crowns'),
    },
    {
        text: 'teeth whitening',
        link: getNavLink('teeth whitening'),
    },
];

export function getNavLink(text: string) {
    const flatLinks = (() => {
        const flat: NavLinksTypes = [];

        const pushFlat = (arr: NavLinksTypes) =>
            arr.forEach(data => {
                if (!data.links) flat.push(data);
                else {
                    if (data.link) {
                        flat.push({
                            text: data.text,
                            link: data.link,
                        });
                    }
                    pushFlat(data.links);
                }
            });

        pushFlat(navLinks);
        return flat;
    })();

    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
