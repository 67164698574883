import { css } from '@emotion/react';

const styles = css`
    line-height: 125.5%;
    font-weight: 600;
    font-family: 'Metropolis', sans-serif;
    text-transform: uppercase;
`;

export const h1 = css`
    ${styles};
    font-size: 2.5rem;

    @media (min-width: 1280px) {
        font-size: 3rem;
    }
`;

export const h2 = css`
    ${styles};
    font-size: 1.875rem;

    @media (min-width: 1280px) {
        font-size: 2.25rem;
    }
`;

export const h3 = css`
    ${styles};
    font-size: 1.375rem;

    @media (min-width: 1280px) {
        font-size: 1.75rem;
    }
`;

export const h4 = css`
    font-size: 1.25rem;
    line-height: 125.5%;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const h5 = css`
    ${styles};
    font-size: 1.25rem;
    text-transform: capitalize;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const smTxt = css`
    font-size: 1rem;

    @media (min-width: 1280px) {
        font-size: 1.125rem;
    }
`;

export const lgTxt = css`
    font-size: 1.125rem;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;

export const oswald = css`
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
`;
