exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-dr-diego-ramirez-tsx": () => import("./../../../src/pages/about-us/dr-diego-ramirez.tsx" /* webpackChunkName: "component---src-pages-about-us-dr-diego-ramirez-tsx" */),
  "component---src-pages-about-us-dr-oscar-vargas-tsx": () => import("./../../../src/pages/about-us/dr-oscar-vargas.tsx" /* webpackChunkName: "component---src-pages-about-us-dr-oscar-vargas-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-before-and-after-tsx": () => import("./../../../src/pages/before-and-after.tsx" /* webpackChunkName: "component---src-pages-before-and-after-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-implants-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/oscar-vargas/oscar-vargas/src/content/services/dental-implants/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-implants-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-prevention-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/oscar-vargas/oscar-vargas/src/content/services/dental-prevention/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-prevention-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-direct-and-indirect-resins-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/oscar-vargas/oscar-vargas/src/content/services/direct-and-indirect-resins/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-direct-and-indirect-resins-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-porcelain-veneers-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/oscar-vargas/oscar-vargas/src/content/services/porcelain-veneers/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-porcelain-veneers-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-smile-design-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/oscar-vargas/oscar-vargas/src/content/services/smile-design/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-smile-design-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-teeth-whitening-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/oscar-vargas/oscar-vargas/src/content/services/teeth-whitening/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-teeth-whitening-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-zirconia-crowns-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/oscar-vargas/oscar-vargas/src/content/services/zirconia-crowns/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-zirconia-crowns-index-mdx" */)
}

