import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)<{ dark?: boolean }>`
    padding: 12px 24px;
    background-color: transparent;
    color: ${({ dark, theme }) => (dark ? theme.colors.dark : theme.colors.white)};
    transition:
        background-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
    text-transform: capitalize;
    border-radius: 64px;
    border: 1px solid ${({ dark, theme }) => (dark ? theme.colors.dark : theme.colors.white)};
    letter-spacing: 2px;

    > svg {
        margin-right: 16px;
    }

    @media (min-width: 1280px) {
        padding: 12px 48px;
    }

    :hover {
        background-color: ${({ theme }) => theme.colors.blue};
        color: ${({ theme }) => theme.colors.white};
    }

    :focus-visible {
        background-color: ${({ theme }) => theme.colors.blue};
        color: ${({ theme }) => theme.colors.white};
    }
`;
