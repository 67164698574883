import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)`
    padding: 12px 24px;
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    transition:
        background-color 0.3s ease-in-out,
        box-shadow 0.3s ease-in-out;
    text-transform: capitalize;
    border-radius: 64px;
    border: 1px solid ${({ theme }) => theme.colors.blue};
    letter-spacing: 2px;

    > svg {
        margin-right: 16px;
    }

    @media (min-width: 1280px) {
        padding: 12px 48px;
    }

    :hover {
        background-color: ${({ theme }) => theme.colors.dark};
        box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.5);
    }

    :focus-visible {
        background-color: ${({ theme }) => theme.colors.dark};
        box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.5);
    }
`;
