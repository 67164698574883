import { CLink } from '@/CLink';
import { PlainBtn } from '@/buttons';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as Logo } from '@a/images/layout/logo.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import useLocationData from '@s/hooks/useLocationData';
import { navigate } from 'gatsby';
import { Fragment, useEffect, useRef, useState } from 'react';
import { NavDialog } from './NavDialog';
import { siteInfo } from './NavLinks';
import { flexSpace, maxWidth, px } from './styles/classes';

const Navbar = styled.header<{ homepage: boolean; scrolled: boolean }>`
    ${px};
    padding-top: 16px;
    padding-bottom: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    z-index: 100;
    isolation: isolate;

    ::before,
    ::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: -1;
        background-image: ${({ homepage, theme }) => (homepage ? 'none' : theme.colors.gradient)};
    }

    ::after {
        background-image: ${({ theme }) => theme.colors.gradient};
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
    }

    > div {
        ${flexSpace};
        ${maxWidth};
        align-items: center;
        margin: 0 auto;
    }

    ${({ scrolled }) =>
        scrolled &&
        css`
            ::after {
                opacity: 1;
            }
        `};
`;

const HomeLink = styled(CLink)`
    transition: opacity 0.3s ease-in-out;
    flex-shrink: 0;
    line-height: auto;

    :hover {
        opacity: 0.7;
    }

    :focus-visible {
        opacity: 0.7;
    }

    @media (max-width: 1023px) {
        > svg {
            width: 167.8px;
            height: 48px;
        }
    }
`;

const CallBtn = styled(PlainBtn)`
    width: 32px;
    height: 32px;
    margin-left: 16px;
    transition: opacity 0.3s ease-in-out;

    :hover {
        opacity: 0.6;
    }

    :focus-visible {
        opacity: 0.6;
    }
`;

export const HomeLogo = () => (
    <HomeLink to="/" aria-label="oscar vargas dental center">
        <Logo />
    </HomeLink>
);

// link can be /meet-the-doctors/#dr-herbert or #dr-herbert
export const scrollToSection = (link: string) => {
    const fullLink = typeof link === 'string' ? link.split('#') : '';
    const element = document.querySelector(`#${fullLink[1] || ''}`);
    if (!element) {
        navigate(fullLink[0], {
            state: {
                tag: `#${fullLink[1] || ''}`,
            },
        });
    }
    const y = element && element.getBoundingClientRect().top + window.scrollY - 76;
    window.scroll({
        behavior: 'smooth',
        top: y || 0,
    });
};

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
`;

export const Nav = () => {
    // const [dialogsClosed, setDialogsClosed] = useState(false);
    // const closeAllDialogs = () => setDialogsClosed(open => !open);
    //                     <MobNav dialogsClosed={dialogsClosed} closeAllDialogs={closeAllDialogs} />

    const [scrolled, setScrolled] = useState(false);

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = setTimeout(() => {
            setScrolled(!visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    const { title } = useLocationData();

    return (
        <Fragment>
            <Navbar scrolled={scrolled} homepage={title === ''}>
                <div>
                    <NavDialog />
                    <HomeLogo />
                    <CallBtn
                        as={CLink}
                        to={siteInfo.phone.link}
                        aria-label={`call us at ${siteInfo.phone.text}`}
                    >
                        <PhoneIcon />
                    </CallBtn>
                </div>
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
