import { CLink } from '@/CLink';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { siteInfo } from './NavLinks';

const Link = styled(CLink)`
    position: fixed;
    bottom: 32px;
    right: 32px;
    z-index: 99;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.16));
    transition: filter 0.3s ease-in-out;

    :hover {
        filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.4));
    }

    :focus-visible {
        filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.4));
    }

    @media (max-width: 1023px) {
        > .gatsby-image-wrapper {
            width: 36px;
            height: 36px;
        }
    }
`;

export const Whatsapp = () => {
    return (
        <Link to={siteInfo.whatsapp.link} aria-label="message us on whatsapp">
            <StaticImage
                src="../../../assets/icons/whatsapp-big.png"
                alt="whatsapp"
                width={64}
                height={64}
                loading="eager"
            />
        </Link>
    );
};
